<script>
import _ from 'lodash'

export default {
	name: 'CommonForm',
	props: {
		layout: {
			type: String,
			default: () => 'horizontal'
		},
		submit: {
			type: Function,
			required: true
		},
		reset: {
			type: Function,
			default: () => {}
		},
		submitLabel: {
			type: String,
			default: 'Apply'
		},
		disableBtn: {
			type: Boolean,
			default: false
		},
		displayBtn: {
			type: Boolean,
			default: true
		},
		displayResetBtn: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return { loading: { submit: false, reset: false } }
	},
	methods: {
		withLoading(func, identifier) {
			return async (...args) => {
				_.set(this.loading, identifier, true)
				await func(...args)
				_.set(this.loading, identifier, false)
			}
		},
		onSubmit() {
			this.withLoading(this.submit, 'submit')()
		},
		onReset() {
			this.withLoading(this.reset, 'reset')()
		}
	}
}
</script>

<template>
	<q-form
		:class="[layout, 'common-form']"
		@submit="onSubmit"
		@reset="onReset"
	>
		<slot />
		<div class="button-container">
			<q-btn
				v-show="displayBtn"
				:loading="loading.submit"
				:label="submitLabel"
				:disable="disableBtn"
				type="submit"
				color="primary"
				no-caps
			/>
			<q-btn
				v-show="displayResetBtn"
				label="Reset"
				:loading="loading.reset"
				:disable="disableBtn"
				type="reset"
				color="primary"
				outline
				no-caps
			/>
			<slot name="extra-buttons" />
		</div>
	</q-form>
</template>

<style lang="scss">
.common-form.q-form {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 10px;
	padding-bottom: 20px;

	> * {
		margin: 0;
	}

	&.vertical {
		flex-direction: column;

		> * {
			align-self: flex-start;
		}
	}

	.row {
		margin: 0;

		.q-field__inner {
			background: #fff;
			padding: 0 5px;
			border: 1px solid #eee;
			border-radius: 5px;
			align-self: auto;
		}
	}

	.button-container {
		display: flex;
		grid-gap: 10px;

		button {
			align-self: flex-start;
			margin-top: 3px;
		}
	}
}
</style>
